<div class="flex gap-3">
  <button class="arrow-button" title="Go back" (click)="back()">
    <svg role="img" focusable="false" height="24" width="24" viewBox="0 0 24 24">
      <polyline points="16 4 7 12 16 20" fill="none" stroke="currentColor"></polyline>
    </svg>
  </button>
  <button class="arrow-button" title="Go forward" (click)="forward()">
    <svg role="img" focusable="false" height="24" width="24" viewBox="0 0 24 24">
      <polyline points="8 4 17 12 8 20" fill="none" stroke="currentColor"></polyline>
    </svg>
  </button>
</div>
<as-social-share></as-social-share>
<as-user-dropdown></as-user-dropdown>
