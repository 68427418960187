<div class="user-info">
  <ng-container *ngIf="userProduct$ | async as userProduct">
    <a
      class="btn-upgrade"
      href="https://www.spotify.com/us/premium/"
      target="_blank"
      aria-roledescription="upgrade-button"
      *ngIf="userProduct !== 'premium'"
    >
      Upgrade
    </a>
  </ng-container>

  <div class="user-dropdown" nz-dropdown [nzDropdownMenu]="menu" [nzPlacement]="'bottomRight'">
    <figure class="w-7 h-7" [title]="userName$ | async">
      <img [src]="userAvatar$ | async" alt="User Avatar" class="rounded-img" />
    </figure>
    <span class="w-16 ml-2 text-xs text-white truncate">{{ userName$ | async }}</span>
    <svg-icon class="mr-2" [key]="'caret-down-fill'"></svg-icon>
  </div>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu class="dropdown-container">
      <li nz-menu-item class="dropdown-item">
        <a class="text-description" routerLink="/future-responsive">
          🏴󠁧󠁢󠁥󠁮󠁧󠁿 Hello London
        </a>
      </li>
      <li nz-menu-item class="dropdown-item">
        <a class="text-description" routerLink="/container-queries">WDC 2023 </a>
      </li>
      <li nz-menu-item class="dropdown-item">
        <a class="text-description" href="https://www.spotify.com/account/" target="_blank">
          Profile
        </a>
      </li>
      <li nz-menu-item class="dropdown-item">
        <a class="text-description" href="https://www.spotify.com/account/apps/" target="_blank">
          Remove Access
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>
